import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import { motion, AnimatePresence } from "framer-motion";
export default function Layout() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Navigation />

      <main>
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
        >
          <Outlet />
        </motion.div>
      </main>

      <Footer />
    </AnimatePresence>
  );
}
