import { createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/Home";
import Layout from "./Layout";
import AboutUsPage from "./pages/AboutUs";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
    ],
  },
]);

export default router;
