import { Link } from "react-router-dom";
import styles from "./style.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <p>
          SteamSets is a community-made project and is not affiliated with Steam
          or Valve corporation.
        </p>

        <div className={styles.links}>
          <Link to="/about-us">About us</Link>
          <Link
            to="https://links.steamsets.com/donate?ref=landing-page"
            target="_blank"
          >
            Donate
          </Link>
          {/* <Link to="/roadmap">Roadmap</Link> */}
        </div>
      </div>
    </footer>
  );
}
